import React, { useMemo, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions as authActions } from 'features/Auth';
import SVG from 'components/SVG';
import Button from 'components/Button';

import logoSvg from './img/logo.svg';
/* import arrowSvg from './img/arrow.svg'; */

import './Header.scss';
import coinsSVG from './img/coins.svg';
import userSVG from './img/user.svg';
import logoutSVG from './img/logout.svg';

const b = block('header-desktop');

const Header = ({ location, history }) => {
  const locale = useSelector(state => state.locale.locale);
  const { role, isAuth, id, balance, currency, name } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const logout = () => dispatch(authActions.logOut());

  /* const isNeedBackButton = useMemo(() => location.pathname !== '/', [location.pathname]); */

  /* const onClick = useCallback(() => {
    if (isNeedBackButton) history.goBack();
  }, [history, isNeedBackButton]); */

  // const text = locale[location.pathname.substring(0, location.pathname.lastIndexOf('/') === 0 ? location.pathname.length : location.pathname.lastIndexOf('/'))];
  /* let text = "";
  if (location.pathname === "/user-create") {
    text = locale[`/user-create-${role}`];
  } else if (location.pathname.lastIndexOf("/") === 0)
    text = locale[location.pathname.substring(0, location.pathname.length)];
  else
    text =
      locale[
        location.pathname.substring(0, location.pathname.lastIndexOf("/"))
      ]; */

  return (
    <div className={b({ isAuth })}>
      <div className={b('logo-wrapper')}>
        <Link to="/" className={b('logo')}>
          <SVG className={b('logo-icon')} svgProps={{ svg: logoSvg }} />
        </Link>
        {locale.adminPanel}
      </div>

      {isAuth && (
        <div className={b('user')}>
          <div className={b('user-content')}>
            <div className={b('user-balance')}>
              <div className={b('user-value')}>
                <SVG className={b('user-svg')} svgProps={{ svg: coinsSVG }} />
                <span className={b('user-balance-value')}>{balance}</span>
              </div>
              <span className={b('user-balance-currency')}>{currency}</span>
            </div>
            <div className={b('user-balance')}>
              <div className={b('user-value')}>
                <SVG className={b('user-svg')} svgProps={{ svg: userSVG }} />
                <span className={b('user-balance-value')}>{name}</span>
              </div>
              <SVG onClick={logout} className={b('logout')} svgProps={{ svg: logoutSVG }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Header);
