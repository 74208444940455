import React, { useMemo, useEffect, useState, useCallback } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import BackBlock from 'components/BackBlock';
import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator';
import useSlotHistoryFilters from 'features/slotsBetHistory/helpers/useSlotHistoryFilters';
import { itemsOnPage } from 'features/slotsBetHistory/helpers/data';

import SlotsHistoryItem from './SlotsHistoryItem';
import SlotsHistoryFilter from './SlotsHistoryFilter';

import './SlotsBetHistory.scss';

const b = block('slots-bet-history-desktop');

const SlotsBetHistory = () => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { actionProcessing } = useSelector(state => state.slotsBetHistory, shallowEqual);

  const [isFiltersOpened, setIsFilterOpened] = useState(true);

  const filterData = useSlotHistoryFilters();
  const { items, state, pageCount, onPageChanged } = filterData;
  const { page } = state;

  const itemsElements = useMemo(
    () =>
      items.map(t => (
        <div key={t.key} className={b('item')}>
          <SlotsHistoryItem item={t} />
        </div>
      )),
    [items],
  );

  return (
    <div className={b()}>
      <Spinner isLoading={actionProcessing} />
      <h3 className={b('title')}>{locale.casinoHistory2}</h3>
      <div className={b('content')}>
        <div className={b('filters', { opened: isFiltersOpened })}>
          <SlotsHistoryFilter filterData={filterData} />
        </div>
        <div className={b('list')}>{itemsElements}</div>
        <div className={b('paginator-container')}>
          <div className={b('paginator')}>
            <Paginator
              count={pageCount}
              currentPage={page}
              onPageClick={newPage => onPageChanged(newPage)}
              itemsOnPage={itemsOnPage}
              items={items}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SlotsBetHistory.propTypes = {};

export default SlotsBetHistory;
