import React, { useState, useEffect, useMemo, useCallback, useRef, forwardRef } from 'react';
import { useDispatch, useSelector, connect, shallowEqual } from 'react-redux';
import { Switch, Route, withRouter, Link, useHistory, useRouteMatch, useLocation, Redirect } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { bindActionCreators } from 'redux';
import block from 'bem-cn';
import DatePicker from 'react-datepicker';

import { dateFormateForSafari } from 'services/helper/dateFormateForSafari';
import calendarSVG from './img/calendar.svg';
import arrowSVG from './img/arrow.svg';

import 'react-datepicker/dist/react-datepicker.css';

import './InputDate.scss';

const b = block('input-date-mobile');

const InputDate = ({ dateFormat, selected, isValid, onChange, color }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b('custom-date-input')} onClick={onClick} ref={ref}>
      {/*<SVGInline*/}
      {/*  className={b("custom-date-input-calendar").toString()}*/}
      {/*  svg={calendarSVG}*/}
      {/*/>*/}
      <span className={b('custom-date-input-value')}>{dateFormateForSafari(value)}</span>
      <SVGInline className={b('custom-date-input-arrow').toString()} svg={arrowSVG} />
    </div>
  ));

  return (
    <div className={b({ valid: isValid, color })}>
      <DatePicker
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};

export default InputDate;
