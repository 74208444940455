import bsw from './img/bsw.png';
// import betradar from './img/betradar.png';
import pragmatic from './img/pragmatic.png';
// import evoplay from './img/evoplay.png';
// import inbet from './img/inbet.png';
import fiable from './img/fiable.png';
import evolution from './img/evolution.png';
// import tvBet from './img/tvBet.png';
import outcome from './img/outcome.png';
// import slotegrator from './img/slotegrator.png';
// import softgaming from './img/softgaming.png';
import xpg from './img/xpg.png';

export const itemsOnPage = 10;

export const providerItems = [
  { value: 'all', name: 'All', active: true },
  // { value: 'UPGAMING', name: 'upgaming' },
  { value: 'BSWGAMES', name: 'Bsw', active: false, icon: bsw },
  // { value: 'BETRADAR', name: 'Betradar', active: false, icon: betradar },
  { value: 'PRAGMATIC', name: 'Pragmatic', active: false, icon: pragmatic },
  { value: 'OUTCOMEBET', name: 'Outcomebet', active: false, icon: outcome },
  // { value: 'EVOPLAY', name: 'Evoplay', active: false, icon: evoplay },
  // { value: 'SLOTEGRATOR', name: 'Slotegrator', active: false, icon: slotegrator },
  // { value: 'INBET', name: 'Inbet', active: false, icon: inbet },
  { value: 'FIABLE', name: 'Fiable', active: false, icon: fiable },
  { value: 'EVOLUTION', name: 'Evolution', active: false, icon: evolution },
  // { value: 'SOFTGAMING', name: 'Softgaming', active: false, icon: softgaming },
  { value: 'XPG', name: 'Xpg', active: false, icon: xpg },
  /* { value: 'EZUGI', name: 'Ezugi', active: false, icon: ezugi }, */
  // { value: 'TVBET', name: 'TVBet', active: false, icon: tvBet },
];
export const gameTypeItems = [
  { value: 'all', name: 'all', active: true },
  { value: 'slots', name: 'casino', active: false },
  { value: 'live-casino', name: 'liveCasino', active: false },
  /* { value: 'fiable', name: 'crashGames', active: false }, */
];
export const statusItems = [
  /* { value: 0, name: 'all', active: true }, */
  { value: 1, name: 'win', active: false },
  { value: 2, name: 'lose', active: false },
];
