import React, { useState } from 'react';
import block from 'bem-cn';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions as authActions } from 'features/Auth';
import SVG from 'components/SVG';
import PopUp from 'components/PopUp';
import Button from 'components/Button';
import ChangePas from 'features/ChangePas/desktop';
import Locale from 'features/locale/view/desktop';

import language from './img/lang.svg';
import pas from './img/pas.svg';
import out from './img/out.svg';
import arrowSvg from './img/arrow.svg';

import './Footer.scss';

const b = block('footer');

const Footer = ({ locale, isAuth }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [contentType, setContentType] = useState('');

  const handleLogOut = () => {
    setContentType('');
    dispatch(authActions.logOut());
  };

  const content = () => {
    switch (contentType) {
      case 'logout':
        return (
          <div className={b('pop-up-container')}>
            <h3 className={b('pop-up-title')}>{locale.wantLogout}</h3>
            <div className={b('pop-up-button')}>
              <Button color="hollow1" onClick={handleLogOut}>
                <div className={b('pop-up-button-text')}>{locale.yesLogout}</div>
              </Button>
            </div>
            <div className={b('pop-up-button')}>
              <Button onClick={() => setContentType('')}>
                <div className={b('pop-up-button-text')}>{locale.noIStay}</div>
              </Button>
            </div>
          </div>
        );

      case 'changePas':
        return <ChangePas />;

      case 'changeLang':
        return <Locale />;

      default:
        return;
    }
  };

  return (
    <footer className={b()}>
      {contentType && (
        <PopUp title={locale[contentType]} onClose={() => setContentType('')}>
          {content()}
        </PopUp>
      )}
      <div className={b('title')}>{locale.other}</div>
      <div className={b('control')}>
        <div className={b('item')} onClick={() => setContentType('changeLang')}>
          <SVG svgProps={{ svg: language }} className={b('item-img')} />
          <span className={b('item-text')}>{locale.changeLang}</span>
          <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
        </div>
        {isAuth && (
          <div className={b('item')} onClick={() => setContentType('changePas')}>
            <SVG svgProps={{ svg: pas }} className={b('item-img')} />
            <span className={b('item-text')}>{locale.changePas}</span>
            <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
          </div>
        )}
        {isAuth && (
          <div className={b('item')} onClick={() => setContentType('logout')}>
            <SVG svgProps={{ svg: out }} className={b('item-img')} />
            <span className={b('item-text')}>{locale.logout}</span>
            <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
