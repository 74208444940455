import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import SVG from 'components/SVG';

import { providerItems } from 'features/slotsBetHistory/helpers/data';

import './SlotsHistoryItem.scss';

const b = block('slots-history-item-desktop');

const SlotsHistoryItem = ({ item }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { currency } = useSelector(state => state.auth, shallowEqual);

  const { amount, date, provider, status, userId } = item;

  const icon = useMemo(
    () => providerItems.find(p => p?.value?.toLowerCase() === provider?.toLowerCase())?.icon || '',
    [provider],
  );

  return (
    <div className={b()}>
      {/* <div className={b('provider')}>
            {icon && <img className={b('provider-icon')} src={icon} alt="icon" />}
            <span className={b('provider-text')}>{provider}</span>
          </div> */}
      <div className={b('item')}>
        <span className={b('item-title')}>{`${locale.date}`}</span>
        <span className={b('item-value')}>{dayjs(date).format('DD.MM.YYYY HH:MM:ss A')}</span>
      </div>
      <div className={b('item')}>
        <span className={b('item-title')}>{`${locale.userId}`}</span>
        <span className={b('item-value')}>{`${userId}`}</span>
      </div>

      {/* <div className={b('item')}>
            <span className={b('item-title')}>{`${locale.userId}`}</span>
            <span className={b('item-value')}>{`${userId}`}</span>
          </div> */}
      <div className={b('item', { end: true })}>
        <span className={b('item-title')}>{`${locale.provider}`}</span>
        <span className={b('item-value')}>{`${provider}`}</span>
      </div>
      <div className={b('item')}>
        <span className={b('item-title')}>{`${locale.bet}`}</span>
        <span className={b('item-value')}>{`${amount} ${currency}`}</span>
      </div>
      <div className={b('item', { end: true })}>
        <span className={b('item-title')}>{`${locale.outcome}`}</span>
        {status === 1 ? (
          <>
            <span className={b('win')}>{`${amount}`}</span>
          </>
        ) : (
          <span className={b('lose')}>{locale.lost /* locale.slotsBetHistoryStatuses[status] */}</span>
        )}
      </div>
    </div>
  );
};

SlotsHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SlotsHistoryItem;
